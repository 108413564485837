import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import { getMetafieldTags, parseTagFeatures } from '../../lib/product';
import MarkdownContent from '../MarkdownContent';
import SmartLink from '../SmartLink';

const DynamicCollectionDescription = ({ blok: { collection } }) => {
  if (!collection) return null;
  const tags = getMetafieldTags(collection.metafields);
  const tagFeatures = parseTagFeatures(tags);
  const partnership = tagFeatures.partnership
    ? tagFeatures.madewell
      ? { name: 'Madewell', url: '/pages/madewell' }
      : tagFeatures.comingSoon
      ? { name: 'Coming Soon', url: '/pages/coming-soon' }
      : tagFeatures.dunkin
      ? { name: "Dunkin'", url: '/pages/dunkin' }
      : tagFeatures.barbie
      ? { name: "Dunkin'", url: '/pages/barbie' }
      : undefined
    : undefined;
  return (
    <Box>
      <MarkdownContent margin={{ bottom: 'none' }}>
        {collection.descriptionHtml}
      </MarkdownContent>
      {partnership && (
        <SmartLink to={partnership.url}>
          Find out more about the partnership.
        </SmartLink>
      )}
    </Box>
  );
};

DynamicCollectionDescription.propTypes = {
  blok: PropTypes.shape({
    collection: PropTypes.object,
  }),
};

export default memo(DynamicCollectionDescription);
